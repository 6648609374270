* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: white;
  flex-direction: column;
}

.webcam-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100vh;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

/* Buttons for taking or uploading a photo */
.scorecard-button {
  width: 200px;
  padding: 15px;
  border: 2px solid black;
  background-color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scorecard-button:hover {
  background-color: #f0f0f0;
}

.icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.icon-button img {
  width: 60px;
  height: 60px;
}

.icon-button p {
  margin-top: 10px;
  font-size: 18px;
}

/* Ensure the image fits within the screen while maintaining its aspect ratio */
.image-fullscreen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
}

/* Updated image container styling to set maximum size for mobile and desktop */
.image-fullscreen-container img {
  max-width: 90vw; /* Limit the image width to 90% of the viewport width */
  max-height: 75vh; /* Limit the image height to 75% of the viewport height */
  object-fit: contain; /* Maintain aspect ratio */
  border: 2px solid black;
}

/* Mobile-specific adjustments for smaller screens */
@media (max-width: 768px) {
  .image-fullscreen-container img {
    max-width: 85vw; /* For mobile, limit image width to 85% of the viewport width */
    max-height: 60vh; /* Limit image height to 60% of the viewport height */
  }

  .scorecard-button {
    width: 150px; /* Reduce button width on mobile */
    padding: 10px;
    font-size: 16px;
  }
}

/* Buttons for retaking the image or continuing */
.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.scorecard-button {
  width: 200px;
  padding: 15px;
  border: 2px solid black;
  background-color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: black; /* Explicitly set font color to black */
  text-decoration: none; /* Ensure no underlining */
  -webkit-touch-callout: none; /* Disable link detection on iOS */
  -webkit-user-select: none; /* Prevent selection */
  user-select: none;
}

.scorecard-button:hover {
  background-color: #f0f0f0;
}

